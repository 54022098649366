<template>
  <div>
    <div class="flex filter mt-10">
      <div><input type="text" placeholder="Номер ТС" v-model="filter.regnum"></div>
      <div><input type="text" placeholder="Регистрация" v-model="filter.registration"></div>
      <div><input type="date" placeholder="Дата регистрации" v-model="filter.registrationDate" ></div>
      <div><input type="text" placeholder="Номер документа" v-model="filter.paymentDoc"></div>
      <div>
        <button class="h-full w-full bg-green-400" @click="search">Поиск</button>
      </div>
      <div>
        <button class="h-full w-full bg-blue-400" @click="unset">Сбросить</button>
      </div>
    </div>
    <div v-if="items.length" class="overflow-y-scroll max-h-128 hide-scroll dark:shadow-none shadow-md mt-1 mb-10">
      <table class=" w-full text-left table dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm ">
        <thead class="dark:bg-gray-800 bg-white text-gray-500">
        <th class="p-3 select-none">№</th>
        <th class="p-3 select-none" v-for="(header, idx) in headers" :key="'header-'+header.value + '_'+ idx"
        >{{ header.name }}
        </th>
        </thead>
        <tbody class="dark:text-gray-400 text-gray-600">
        <tr class="dark:bg-gray-800 bg-white cursor-pointer dark:hover:bg-gray-700 hover:bg-gray-300 "
            v-for="(data, idx) in items" :key="'payment-'+idx"
        >
          <td class="p-3">{{ idx + 1 }}</td>

          <td class="p-3 " :key="header.value + '_'+ idx" v-for="(header, idx) in headers">

            <span> {{ data[header.value] }}</span>
          </td>

        </tr>
        </tbody>
      </table>

    </div>
    <SimplePagination v-if="items.length" dashboard :pagination.sync="pagination" :hideNext="hideNext"></SimplePagination>
    <div v-else>
      <h3 class="text-2xl text-center w-full p-10">
        Введите данные и нажмите поиск
      </h3>
    </div>

  </div>
</template>

<script>

import  SimplePagination from  '@/components/common/SimplePagination'
import moment from "moment";
export default {
  name: "UnusedPayments",
  components: {
     SimplePagination
  },
  data: () => ({
    items: [],
    selected: {},
    show: false,
    filter:{
      regnum:"",
      registration:"",
      paymentDoc:"",
      registrationDate:""
    },
    pagination:{
      page:1,
      perPage:15,
    },
    headers: [
      {
        name: "Регистрация",
        value: "registration"
      },
      {
        name: "Номер ТС",
        value: "regnum"
      },
      {
        name: "Дата регистрации",
        value: "registrationDate"
      },
      {
        name: "Статус",
        value: "status"
      },
      {
        name: "ЗО",
        value: "checkpoint"
      },
      {
        name: "Номер документа",
        value: "paymentDoc"
      },
      {
        name: "Дата оплаты",
        value: "paymentDate"
      },

    ]
  }),
  computed:{
    hideNext() {
      return this.items.length === 0 || this.items.length < this.pagination.perPage;
    }
  },
  watch:{
    pagination:{
      handler(){
        this.fetchItems()
      },
      deep:true
    }
  },
  methods: {
    unset(){
      this.filter = {
        regnum:"",
        registration:"",
        paymentDoc:"",
        registrationDate:""
      }
      this.items = []
    },
    search(){
      this.fetchItems()
    },
    fetchItems() {
      const payload = {
        filter: {
          ...this.filter,
          registrationDate: this.filter.registrationDate ? moment(this.filter.registrationDate, "YYYY-MM-DD").format("DD.MM.YYYY") : ""
        },
        ...this.pagination
      }
      this.$store.dispatch('clientPayments/searchPayment',payload).then(res => {
        this.items = res.data.data || []
      }).catch(err => {
        this.$notify({
          title: 'Ошибка',
          text: err.response.data.message,
          type: 'error'
        })
      })
    },
    openModal(payment) {
      this.selected = payment
      this.show = true
    }
  }

}
</script>

<style scoped>
.filter div {
  @apply w-1/4 mr-1;
}
.datepicker{
  width: 100%!important;
}
input, .datepicker {
  max-height: 44px!important;
  @apply text-black placeholder-gray-600 w-full px-4 py-2.5  text-base dark:placeholder-gray-400
  transition duration-500 ease-in-out transform border-transparent
  bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
  dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
  dark:focus:text-white
}
</style>